<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    비밀번호를 입력하세요.
                </h2>
            </div>
            <form class="mt-8 space-y-6" @submit.prevent="submit">
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="pwd" class="sr-only">Password</label>
                        <input id="pwd" name="pwd" type="password" v-model="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
                    </div>
                </div>

                <div>
                    <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        확인
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Manager',
    data: function() {
        return {
            password: '',
            baseUrl: this.$debug ? 'http://localhost:8080' : '',
        }
    },
    methods: {
        submit: function() {
            axios.post(this.baseUrl + '/api/manager/login', {
                pwd: this.password
            }).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    this.$router.push('/man/download');
                }
            }).catch(() => {
                alert('비밀번호를 확인해주세요.');
            });
        }
    }
}
</script>

<style scoped>
</style>